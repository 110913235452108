import React from "react";
import { Button, message } from "antd";
import { LockOutlined, UnlockOutlined } from "@ant-design/icons";
import api from "../../api";

export const LockButton = ({ projectId, material, record, lock_recommendation, onLockRecommendation, data }) => {
    const handleClick = async () => {
        const dataToSend = {
            updated: record,
            data: data,
        };

        const apiUrl = `/projects/${projectId}/material-analysis/${material}/lock-recommendation`;

        try {
            const response = await (lock_recommendation ? api.delete(apiUrl, { data: dataToSend }) : api.post(apiUrl, dataToSend));
            message.success(`Recommendation ${lock_recommendation ? "unlocked" : "locked"} successfully!`);
            onLockRecommendation(response.data);
        } catch (error) {
            console.error(`Failed to ${lock_recommendation ? "unlock" : "lock"} recommendation:`, error);
            message.error(`Failed to ${lock_recommendation ? "unlock" : "lock"} recommendation.`);
        }
    };

    return (
        <Button
            icon={lock_recommendation ? <LockOutlined /> : <UnlockOutlined />}
            onClick={handleClick}
            style={{
                color: lock_recommendation ? "#1890ff" : "inherit",
                borderColor: lock_recommendation ? "#1890ff" : "#d9d9d9",
            }}
        />
    );
};

export default LockButton;