import React, {useEffect, useState} from "react";
import api from "../../api";
import dayjs from 'dayjs';
import {ForecastingGraph} from "./ForecastingGraph";
import {Button, Checkbox, Col, DatePicker, Descriptions, Input, InputNumber, message, Row, Select, Spin} from "antd";
import {EditOutlined} from "@ant-design/icons";
import {DEFAULT_FORECAST_END, ROP_MIN_LABEL} from "../../properties";
import {ModalWindow} from "../helpers/modals/ModalWindow";
import {StandardForm} from "../helpers/forms/StandardForm";
import {useForm} from "antd/es/form/Form";


function Edit ({onSave, defaultValue, _type, options = []}) {

    const [_value, setValue] = useState()
    const [dateValue, setDateValue] = useState()

    const workWithDate = (date, dateString) => {
        setValue(dateString)
        setDateValue(date)
    }

    const getInput = () => {
        if (_type === 'numeric') {
            return <InputNumber defaultValue={defaultValue} value={_value} onChange={event => {
                setValue(event)
            }}/>
        }

        if (_type ==='date') {
            return <DatePicker defaultPickerValue={dayjs(defaultValue, 'YYYY-MM')}
                               defaultValue={dayjs(defaultValue, 'YYYY-MM')}
                               onChange={workWithDate}
                               value={dateValue}
                               picker="month" />
        }

        if (_type === 'checkbox') {
            return <Checkbox style={{marginRight: 10}} defaultChecked={defaultValue} value={_value} onChange={event => setValue(event.target.checked)} />
        }

        if (_type === 'select') {
            return <Select style={{marginRight: 10, width: 150}} defaultValue={defaultValue} value={_value} onChange={value => setValue(value)} options={options}  />
        }
        return <Input defaultValue={defaultValue} value={_value} onChange={event => setValue(event.target.value)} />
    }

    return  <div>
        {getInput()}
        <Button onClick={() => {
                onSave(_value)
        }} type="primary">Submit</Button>
    </div>
}

export function SimpleForecasting({projectId,material, itemInfo, forecastMin, forecastMax, canEdit, onRecAdd, change, currentRec, constants}) {

    const [min, setMin] = useState(itemInfo['rop'])
    const [max, setMax] = useState(itemInfo['max'])
    const [holding, setHolding] = useState(18)
    const [end, setEnd] = useState(DEFAULT_FORECAST_END)

    const [usePm, setUsePm] = useState("corrective")

    const [title, setTitle] = useState(currentRec)

    const [isModalVisible, setIsModalVisible] = useState(false)

    const [editingMode, setEditingMode] = useState(false)
    const [editField, setEditField] = useState('')

    const [graph, setGraph] = useState([])
    const [info, setInfo] = useState({})

    const [loading, setLoading] = useState(false)

    const [form] = useForm()

    const save = () => {
        form.submit()
    }

    useEffect(() => {
        setTitle(currentRec)
    }, [currentRec])

    const getForecast = (_min=min, _max=max, _holding=holding, _end=end, useForecast = false, _usePm = usePm) => {
        setLoading(true)
        if (_min === '' || _max === '') {
            _min = itemInfo['rop']
            _max = itemInfo['max']
        }

        if (useForecast) {
            _min = forecastMin || _min
            _max = forecastMax || _max
        }

        const data = {min: _min, max: _max, holding: _holding / 100, end:_end, use_pm: _usePm}
        api.post(`/projects/${projectId}/material-analysis/${material}/forecast`, data)
            .then(json => {
                setGraph(json.data.graph)
                setInfo(json.data.info)
                setMin(_min)
                setMax(_max)
                setHolding(_holding)
                setEnd(json.data.info['actual_end_date'])
                setUsePm(_usePm)
            })
            .finally(() => setLoading(false))
    }

    const canSaveUser = () => {
        // if (min === itemInfo['rop'] && max === itemInfo['max']) {
        //     return false
        // }
        // else if (min === forecastMin && max === forecastMax) {
        //     return false
        // }

        return true
    }

    useEffect(() => {
        getForecast(min, max, holding, end, true, constants['use_pm'])
        setTitle(currentRec)
    }, [itemInfo, forecastMax, forecastMin, change, constants])



    const startEdit = field => {
        setEditField(field)
        setEditingMode(true)
    }

    const finishEdit = () => {
        setEditField('')
        setEditingMode(false)
        setTitle('[User] User recommendation')
    }

    const getValuesFields = () => [
        {label: 'Recommendation Name', name: 'recommendation_name', _type: 'text', required: true},
    ]

    const onOk = (values) => {
        console.log(values)
        let userRec = {
            "material" : itemInfo['material'],
            "name" : values.recommendation_name ,
            "type" : "User",
            "raw" : "",
            "apply" : "",
            "min" : min,
            "max" : max,
        }

        api.post(`/projects/${projectId}/material-analysis/${material}/user-recommendations`, userRec)
            .then(json => {
                message.success("User Recommendations Successfully")
                setIsModalVisible(false)
            })

        onRecAdd()
    }

    const selectOptions = [{
        value: 'all',
        label: 'All'
    }, {
        value: 'corrective',
        label: 'Corrective'
    },{
        value: 'planned',
        label: 'Planned'
    }]

    return <div>
        <div style={{textAlign: 'center'}}>
            <div style={{fontWeight: 'bold'}}>Selected Recommendation: </div>
            <div >{title}</div>
        </div>
        <Spin spinning={loading}>
            <Row>
                <Col span={8}>
                <Descriptions size={'small'} title="Material Data" bordered>
                    <Descriptions.Item labelStyle={{fontWeight: 'bold'}} label={"Price per Unit"} span={2}>
                        {info['price']}
                    </Descriptions.Item>
                    <Descriptions.Item labelStyle={{fontWeight: 'bold'}} label={"Current Inventory"} span={1}>
                        {info['stock_level']}
                    </Descriptions.Item>
                    <Descriptions.Item labelStyle={{fontWeight: 'bold'}} label={"Lead Time (days)"} span={2}>
                        {info['lead_time_days']}
                    </Descriptions.Item>
                    <Descriptions.Item labelStyle={{fontWeight: 'bold'}} label={"Lead Time (months)"} span={1}>
                        {info['lead_time_months']}
                    </Descriptions.Item>

                    <Descriptions.Item labelStyle={{fontWeight: 'bold'}} label={"Old Max"} span={2}>
                        {info['old_max']}
                    </Descriptions.Item>
                    <Descriptions.Item labelStyle={{fontWeight: 'bold'}} label={`Old ${ROP_MIN_LABEL}`} span={1}>
                        {info['old_rop']}
                    </Descriptions.Item>
                    <Descriptions.Item labelStyle={{fontWeight: 'bold'}} label={"Old Average"} span={2}>
                        {info['old_average']}
                    </Descriptions.Item>
                    <Descriptions.Item labelStyle={{fontWeight: 'bold'}} label={"New Average"} span={1}>
                        {info['new_average']}
                    </Descriptions.Item>
                    <Descriptions.Item labelStyle={{fontWeight: 'bold'}} label={"Old Average (real)"} span={2}>
                        {info['old_average_real']}
                    </Descriptions.Item>
                    <Descriptions.Item labelStyle={{fontWeight: 'bold'}} label={"New Average (real)"} span={1}>
                        {info['new_average_real']}
                    </Descriptions.Item>
                    <Descriptions.Item labelStyle={{fontWeight: 'bold'}} label={"Real Saving PA"} span={2}>
                        {info['real_savings']}
                    </Descriptions.Item>
                    <Descriptions.Item labelStyle={{fontWeight: 'bold'}} label={"Average Saving PA"} span={1}>
                        {info['ave_savings']}
                    </Descriptions.Item>
                    <Descriptions.Item labelStyle={{fontWeight: 'bold'}} label={"Service factor"} span={2}>
                        {info['service_factor']} %
                    </Descriptions.Item>
                    <Descriptions.Item labelStyle={{fontWeight: 'bold'}}  label={"End Date"} span={1}>
                        <div>{end}</div>
                    </Descriptions.Item>

                </Descriptions>
                {canSaveUser() && <div style={{
                    paddingTop: '10px',
                    paddingBottom: '10px',
                }}><Button type={'primary'} style={{
                    float: "right",
                }} onClick={() => setIsModalVisible(true)}>
                    Save as a recommendation
                </Button></div>}
                <Descriptions size={'small'} title="Model Parameters" bordered>

                    <Descriptions.Item labelStyle={{fontWeight: 'bold'}} label={"New Max"} span={3}>
                        {(!editingMode || editField !== 'max') && <div>{info['new_max']}{canEdit && <EditOutlined
                            onClick={() => startEdit('max')}/>}</div>}
                        {(editingMode && editField === 'max') && <Edit defaultValue={info['new_max']} _type={'numeric'} onSave={(value) => {
                            setMax(value)
                            finishEdit()
                            getForecast(min ,value, holding, end, )
                        }}/> }
                    </Descriptions.Item>
                    <Descriptions.Item labelStyle={{fontWeight: 'bold'}} label={`New ${ROP_MIN_LABEL}`} span={3}>
                        {(!editingMode || editField !== 'rop') && <div>{info['new_rop']} {canEdit && <EditOutlined
                            onClick={() => startEdit('rop')}/>}</div>}
                        {(editingMode && editField === 'rop') && <Edit defaultValue={info['new_rop']} _type={'numeric'} onSave={(value) => {
                            setMin(value)
                            finishEdit()
                            getForecast(value, max, holding, end)
                        }}/> }
                    </Descriptions.Item>
                    <Descriptions.Item labelStyle={{fontWeight: 'bold'}} label={"Holding"} span={3}>
                        {(!editingMode || editField !== 'holding') && <div>{holding} <EditOutlined onClick={() => startEdit('holding')}/></div>}
                        {(editingMode && editField === 'holding') && <Edit defaultValue={holding} _type={'numeric'} onSave={(value) => {
                            setHolding(value)
                            finishEdit()
                            getForecast(min, max, value, end)
                        }}/> }
                    </Descriptions.Item>
                    <Descriptions.Item labelStyle={{fontWeight: 'bold'}}  label={"Demand forecast data"} span={3}>
                        {(!editingMode || editField !== 'use_pm') && <div>{usePm}<EditOutlined onClick={() => startEdit('use_pm')}/></div>}
                        {(editingMode && editField === 'use_pm') && <Edit options={selectOptions} defaultValue={usePm} _type={'select'} onSave={(value) => {
                            setUsePm(value)
                            finishEdit(finishEdit)
                            getForecast(min, max, holding, end, false, value)
                        }}/> }
                    </Descriptions.Item>
                </Descriptions>

            </Col>
            <Col span={16} style={{backgroundColor: 'white', padding: 5}}>

                <ForecastingGraph data={graph} labels={['stock_level', 'rop', 'max']}
                                  legendFields={['History Stock','Reorder Point', 'MAX' ]}
                                  colors={['blue', '#00ff8033', '#ff00004d', '#00D30966']} />

            </Col>
        </Row>
    </Spin>
        <ModalWindow title={`Save new recommendation`}
                     isModalVisible={isModalVisible}
                     onOk={() => save()}
                     scrollVisible={false}
                     onCancel={() => setIsModalVisible(false)}>
            <StandardForm form={form} initialValues={{}} onFinish={onOk} fields={getValuesFields()} />
        </ModalWindow>
    </div>
}
