import {ModalWindow} from "./ModalWindow";
import {Descriptions} from "antd";
import React from "react";

export function InfoModal({isModalVisible, setModalVisible, iteration}) {

    return <ModalWindow title={`${iteration.iteration_name} Info`}
                        isModalVisible={isModalVisible}
                        modalProps={{height: '40vh'}}
                        onOk={() => setModalVisible(false)}
                        scrollVisible={false}
                        onCancel={() => setModalVisible(false)}>
        <div>
                <Descriptions  bordered>
                    <Descriptions.Item span={24} label="Customer service factor">{iteration.constants.csf}</Descriptions.Item>
                    <Descriptions.Item span={24} label="Service Level">{iteration.constants.service_level}</Descriptions.Item>
                    <Descriptions.Item span={24}  label="Mean Absolute Deviation">{iteration.constants.mad}</Descriptions.Item>
                    <Descriptions.Item span={24}  label="Holding">{iteration.constants.holding * 100}</Descriptions.Item>
                </Descriptions>
        </div>

    </ModalWindow>
}
