import React, {Component, useEffect, useState} from "react";
import {checkAllPermissions, checkAnyOfPermissions, getProjectId, getURLParam} from "../../helpers";
import api from "../../api";
import {Button, Col, Descriptions, message, Row, Select, Spin, Tabs} from "antd";
import {GetBack} from "../helpers/cards/MaterialBlock";
import {AssignApproval} from "../workflows/AssignApproval";
import {EditingMaterialModal} from "../helpers/modals/EditingMaterialModal";
import {HistoryModal} from "../helpers/modals/HistoryModal";
import {RecommendationsTable} from "./RecommendationsTable";
import {MaterialCard} from "./MaterialCard";
import {Forecasts} from "./Forecasts";
import {CurrentUserContext} from "../../contexts/AuthContext";
import {CustomDescriptions} from "../settings/MinMaxSettings";
import * as Auth from "../../AuthService";
import {ModalWindow} from "../helpers/modals/ModalWindow";
import {ROP_MIN_LABEL} from "../../properties";
import {StandardTable} from "../helpers/TablePanel";
import DescriptionsItem from "antd/es/descriptions/Item";

const {TabPane} = Tabs;

export class MinMaxItem extends Component {

    constructor(props) {
        const projectId = getProjectId(props.location);

        const material = getURLParam('material')
        const from = getURLParam('from')
        super(props);
        this.state = {
            projectId: projectId,
            material: material,
            shouldSelectWorkflow: true,
            editModal: false,
            canEdit: false,
            canApprove: false,
            historyModal: false,
            from: from,
            standardRecs: [],
            hasRights: false,
            change: false,
            leadTime: [],
            currentTab: '11',
            currentRec: '[MM Data] Current',
            recommendedData: [],
            data: [],
            materialSettings: {},
            forecastMethod: 'naive',
            itemInfo: {
                description: '',
                rop: '',
                max: '',
                Type: ''
            }
        }
    }

    getAnalysis() {
        api.get(`/projects/${this.state.projectId}/material-analysis/${this.state.material}/itemInfo`)
            .then(json => {
                this.setState({itemInfo: json.data.itemInfo})
                if (json.data.itemInfo.approved) {
                    this.setState({
                        forecastMin: json.data.itemInfo.appr_min,
                        forecastMax: json.data.itemInfo.appr_max,
                    })
                }
                this.refreshRecommendations()
            }).catch(e => {
            if (e.response.status === 403) {
                window.open(this.state.from, '_self')
            }
        })
    }

    getRecs = (useMl) => {


        if (useMl) {
            this.setState({mlLoading: true});
            message.info('Getting the AIML recommendation')
        } else {
            this.setState({standardLoading: true});
            message.info('Getting the recommendations')
        }

        api.get(`/projects/${this.state.projectId}/material-analysis/${this.state.material}/recommendations?type=unranked&ml=${useMl}`)
            .then(json => {
                this.setState({recommendedData: json.data, getRanked: false,})
                message.loading('Starting the process of ranking the recommendations')
            }).catch(e => {
            if (e.response.status === 403) {
                window.open(this.state.from, '_self')
            }
        }).finally(() => {
            if (useMl) {
                this.setState({mlLoading: false});
            } else {
                this.setState({standardLoading: false});
            }
        })
    }

    getStandardRecs = () => {
        const hide = message.loading('Get the recommendations...', 0, () => {
        });
        api.get(`/projects/${this.state.projectId}/material-analysis/${this.state.material}/recommendations/standard?ml=true`)
            .then(json => {
                this.setState({standardRecs: json.data})
            }).catch(e => {
            if (e.response.status === 403) {
                window.open(this.state.from, '_self')
            }
        }).finally(() => setTimeout(hide, 1))
    }

    componentDidMount() {

        api.get(`/projects/${this.state.projectId}/settings`, Auth.createConfig()).then(json => this.setState({slTable: json.data.constants.service_factor_table}))

        this.getAnalysis()
        this.checkAdminPermission()
        this.checkCanApprovePermission()
        this.getLeadTime()
        this.getValues('global')
        this.getValues(this.state.material)
    }

    getValues = (_type) => {
        if (_type === 'global') {
            api.get(`/projects/${this.state.projectId}/global-settings`)
                .then(json => this.setState({globalSettings: json.data.constants}))
                .catch(e => this.setState({globalSettings: {}}))
        } else {
            api.get(`/projects/${this.state.projectId}/settings/${_type}`)
                .then(json => this.setState({customSettings: json.data.constants}))
                .catch(e => this.setState({customSettings: {}}))
        }
    }

    checkAdminPermission = () => {
        checkAnyOfPermissions(['senior-user', 'approve-user', 'insurance-admin', 'admin', 'superadmin', 'material-analyst', 'inventory-analyst', 'dm-maintenance', 'dm-inventory', 'stock-owner'])
            .then(() => this.setState({hasRights: true, canEdit: true}))
            .catch(() => this.setState({hasRights: false, canEdit: true}))
    }

    checkCanApprovePermission = () => {
        checkAllPermissions(['approve-user'])
            .then(() => this.setState({canApprove: true}))
            .catch(() => this.setState({canApprove: false}))
    }

    getDiff = values => {
        const diff = []
        for (let key of Object.keys(values)) {
            if (this.state.itemInfo.hasOwnProperty(key)) {
                if (key === 'approved') {
                    if (this.state.itemInfo['approved'] === 'NA' && values[key]) {
                        diff.push({
                            key,
                            old: false,
                            new: true
                        })
                    } else if (this.state.itemInfo[key] !== values[key]) {
                        diff.push({
                            key,
                            old: this.state.itemInfo[key],
                            new: values[key]
                        })
                    }
                } else if (this.state.itemInfo[key] !== values[key]) {
                    diff.push({
                        key,
                        old: this.state.itemInfo[key],
                        new: values[key]
                    })
                }
            } else {
                if (['approved', 'comment'].indexOf(key) > -1) {
                    continue
                }
                diff.push({
                    key,
                    old: 'NA',
                    new: values[key]
                })
            }
        }
        return diff;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if ((prevState.globalSettings !== this.state.globalSettings || prevState.customSettings !== this.state.customSettings) || (this.state.itemInfo !== prevState.itemInfo)) {
            const _global = {...this.state.globalSettings}

            const _current = {...this.state.customSettings}
            for (let key of ['holding']) {

                if (Object.keys(_global).includes(key)) {
                    _global[key] = _global[key] * 100

                }

                if (Object.keys(_current).includes(key)) {
                    _current[key] = _current[key] * 100
                }
            }

            for (let key of ['use_pm']) {

                if (_global[key] === true)  {
                    _global[key] = 'all'

                } else if (_global[key] === false)  {
                    _global[key] = 'corrective'
                }

                if (_current[key] === true)  {
                    _current[key] = 'all'

                } else if (_current[key] === false)  {
                    _current[key] = 'corrective'
                }
            }

            let mat_criticality = this.state.itemInfo['criticality']
            if (mat_criticality) {
                if (mat_criticality.includes('A')) {
                    _global['service_level'] = _global['service_level_a']
                } else if (mat_criticality.includes('B')) {
                    _global['service_level'] = _global['service_level_b']
                } else if (mat_criticality.includes('C')) {
                    _global['service_level'] = _global['service_level_c']
                } else {
                    _global['service_level'] = _global['service_level_global']
                }
            }

            this.setState({
                materialSettings: {..._global, ..._current}
            })
        }

        if (this.state.recommendedData !== prevState.recommendedData && !this.state.getRanked) {
            this.getRanked()
        }

        if (this.state.currentTab !== prevState.currentTab && prevState.currentTab === '12') {
            this.setState({
                forecastMethod: 'naive',
                change: !this.state.change,
                currentRec: '[MM Data] Current',
                forecastMin: this.state.itemInfo['rop'],
                forecastMax: this.state.itemInfo['max']
            })
        }
    }

    getRanked = () => {
        if (this.state.recommendedData.length > 0) {
            this.setState({
                getRanked: true
            });
            api.post(`/projects/${this.state.projectId}/material-analysis/${this.state.material}/recommendations?type=ranked`, this.state.recommendedData)
                .then(json => {
                    let new_set_of_recs = json.data.map(x => x.name);
                    let recs = this.state.recommendedData.filter(x => !new_set_of_recs.includes(x.name));

                    this.setState({ recommendedData: [...recs, ...json.data] });
                }).catch(e => {
                if (e.response.status === 403) {
                    window.open(this.state.from, '_self');
                }
            }).finally(() => {
                this.setState({
                    getRanked: true
                });
            });
        }
    }

    removeKeys = (values) => {
        let data = {...values}
        delete data["appr_min"]
        delete data["appr_max"]
        return data
    }

    getLeadTime = () => {
        api.get(`/projects/${this.state.projectId}/material-analysis/${this.state.material}/leadTime`)
            .then(json => this.setState({leadTime: json.data}))
    }

    save = (values, form) => {
        const diff = this.getDiff(values)
        if (diff.length === 0) {
            this.close()
        }
        if (!values.approved) {
            values = this.removeKeys(values)
        }
        const data = {
            values: {...values},

            add_info: {
                user: this.context.email(),
                material: this.state.material,
                date: new Date(),
                comment: values.comment,
                changed: diff
            }
        }

        message.info('Saving...')
        api.post(`/projects/${this.state.projectId}/materials/${this.state.itemInfo._id}/save`, data)
            .then(() => {
                message.success('Your changes were saved')
                this.setState({editModal: false})
                this.getAnalysis()
            }).catch(() => message.error('Something went wrong during saving. Please try again later.'))
    }

    close = () => {
        this.setState({historyModal: false})
    }

    handleSelect = (values) => {
        this.setState({forecastMethod: values})
    }

    startApproval = item => {
        this.setState({
            show: true,
            shouldSelectWorkflow: false,
            proposedMin: item['min'],
            proposedMax: item['max']
        })
    }

    closeApproval = () => {
        this.setState({
            shouldSelectWorkflow: true,
            show: false,
        })
    }

    onForecastOpen = item => {
        console.log(item)
        this.setState({
            currentTab: '12',
            forecastMethod: 'naive',
            currentRec: `[${item.type}] ${item.name}`,
            change: !this.state.change,
            forecastMin: item['min'],
            forecastMax: item['max']
        })
    }

    createSLAttributes = (attributes) => {
        return attributes && attributes.map(a => {
            return {
                value: a['sl'], label: `Service Level: ${a.sl}%, CSF -  ${a.csf}`
            }
        });
    }

    handleSave = (value) => {
        for (let key of Object.keys(value)) {
            if (key === 'service_level') {
                value['csf'] = this.state.slTable.filter(v => v.sl === value['service_level'])[0]['csf']
            }
        }


        const _globalSettings = {...this.state.materialSettings, ...value}

        _globalSettings['holding'] = _globalSettings['holding'] / 100
        api.post(`/projects/${this.state.projectId}/settings/save/${this.state.material}`, _globalSettings).then(() => {
            message.success("Material Settings were updated successfully!")
            this.getValues(this.state.material);
            this.getAnalysis()
            message.info('Start re-ranking the recommendations')
            this.getRanked()
        })
    }

    getRecommendations = () => {
        return [...this.state.recommendedData]
    }

    getAdjustableSettings = () => [
        {
            label: 'Service Level', key: 'service_level', editable: true,
            formFields: [{
                label: 'Service Level',
                name: 'service_level',
                _type: 'select-object',
                message: 'Error',
                required: true,
                options: this.createSLAttributes(this.state.slTable)
            }]
        },
        {
            label: 'Holding Cost', key: 'holding', editable: true,
            formFields: [{
                label: "Holding Cost (%)",
                name: 'holding',
                _type: 'number',
                message: 'Error',
                required: true,
            }]
        }, {
            label: 'Demand forecast data', key: 'use_pm', editable: true,
            formFields: [{
                label: "Demand forecast data",
                name: 'use_pm',
                _type: 'select-object',
                message: 'Error',
                required: true,
                options: [{
                    value: 'all',
                    label: 'All'
                }, {
                    value: 'corrective',
                    label: 'Corrective'
                },{
                    value: 'planned',
                    label: 'Planned'
                }]
            }]
        }, {
            label: 'Use as main recommendation (for BOM forecast)', key: 'recommendation', editable: true,
            formFields: [{
                label: "Use as main recommendation (for BOM forecast)",
                name: 'recommendation',
                _type: 'select-object',
                message: 'Error',
                required: true,
                options: [{
                    value: 'max_installed',
                    label: 'Maximum Quantity Installed per Unit'
                }, {
                    value: 'total_installed',
                    label: 'Total Quantity Installed'
                }]
            }]
        }, {
            label: 'Percentage (for BOM forecast) (%)', key: 'recommendation_percentage', editable: true,
            formFields: [{
                label: "Percentage (for BOM forecast) (%)",
                name: 'recommendation_percentage',
                _type: 'number',
                message: 'Error',
                required: true,
            }]
        },
    ]

    onRecAdd = () => {
        this.getRecs(false)
    }

    onStockAllocationOpen = (item) => {
        this.setState({showStockAllocation: true, saInProgress: true})
        api.post(`/projects/${this.state.projectId}/material-analysis/${this.state.material}/stockAllocation`, item)
            .then(response => {
                this.setState({stockAllocation: response.data, selectedRec: item})
                console.log(response)
            }).finally(() => this.setState({saInProgress: false}))
    }

    refreshRecommendations = () => {
        this.setState({
            recommendedData: []
        })
        this.getRecs(false)
        this.getRecs(true)
    }

    render() {
        return (
            <div>
                <Row style={{padding: 15}}>
                    <Col span={1}>
                        <GetBack link={this.state.from}/>
                    </Col>

                    <Col span={16}>
                        <MaterialCard itemInfo={this.state.itemInfo}
                                      leadTime={this.state.leadTime}
                                      canEdit={this.state.canEdit}
                                      material={this.state.material}
                                      onEdit={() => this.setState({editModal: true})}
                                      onHistoryOpen={() => this.setState({historyModal: true})}

                        />
                    </Col>
                    <Col span={1}/>
                    {<Col span={6}>
                        <AssignApproval projectId={this.state.projectId}
                                        show={this.state.show}
                                        shouldSelectWorkflow={this.state.shouldSelectWorkflow}
                                        closeApproval={this.closeApproval}
                                        onApprove={() => this.getAnalysis()}
                                        proposedMin={this.state.proposedMin}
                                        proposedMax={this.state.proposedMax}
                                        approvalStatus={value => this.setState({approvalStatus: value})}
                                        itemInfo={this.state.itemInfo}/>
                    </Col>}
                </Row>

                <Row style={{padding: 15}}><Col span={24}>
                    <Tabs defaultActiveKey={"11"}
                          onChange={activeKey => this.setState({currentTab: activeKey})}
                          activeKey={this.state.currentTab} style={{marginTop: -15}}>
                        {this.state.hasRights && <TabPane tab={`Recommendations Overview`} key={"11"}>
                            {this.state.standardLoading && <div style={{float: 'left'}}>
                                <Spin spinning={this.state.standardLoading}/>Loading Standard Recommendations

                            </div>}
                            {this.state.mlLoading && <div style={{float: 'right'}}>
                                <Spin spinning={this.state.mlLoading}/>Loading AIML Recommendation
                            </div>}
                            <RecommendationsTable
                                onApprove={this.startApproval}
                                approvalStatus={this.state.approvalStatus}
                                onForecastOpen={(item) => this.onForecastOpen(item)}
                                couldApprove={!this.state.itemInfo.approved}
                                onStockAllocationOpen={this.onStockAllocationOpen}
                                onLockRecommendation={(updatedData) => {
                                    this.setState({ recommendedData: updatedData });
                                }}
                                projectId={this.state.projectId}
                                material={this.state.material}
                                data={this.state.recommendedData} // Передаем данные таблицы
                            />
                        </TabPane>}
                        {(this.state.hasRights && this.state.itemInfo.approved) && <TabPane  tab={`Standard Recommendations Overview`} key={"11-2"}>
                            {this.state.mlLoading && <div style={{float: 'right'}}>
                                <Spin spinning={this.state.mlLoading}/>Loading AIML Recommendation
                            </div>}
                            <div style={{float: 'right'}}>
                                <Button onClick={() => this.getStandardRecs()}>Get recommendations</Button>
                            </div>
                            {this.state.standardRecs.length > 0 && <RecommendationsTable onApprove={this.startApproval}
                                                   approvalStatus={this.state.approvalStatus}
                                                   onForecastOpen={(item) => this.onForecastOpen(item)}
                                                   couldApprove={!this.state.itemInfo.approved}
                                                   onStockAllocationOpen={this.onStockAllocationOpen}
                                                   data={this.state.standardRecs}/>}
                        </TabPane>}
                        <TabPane tab={`Stock Allocation`} key={"sa"}>

                            <StockAllocationTable recs={this.getRecommendations()} projectId={this.state.projectId}
                                                  material={this.state.material}/>

                        </TabPane>
                        <TabPane tab={`Forecasting`} key={"12"}>
                            <Forecasts projectId={this.state.projectId}
                                       material={this.state.material}
                                       itemInfo={this.state.itemInfo}
                                       onRecAdd={this.onRecAdd}
                                       constants={this.state.materialSettings}
                                       currentRec={this.state.currentRec}
                                       change={this.state.change}
                                       handleSelect={this.handleSelect}
                                       canEdit={!this.state.itemInfo.approved}
                                       forecastMethod={this.state.forecastMethod}
                                       forecastMin={this.state.forecastMin}
                                       forecastMax={this.state.forecastMax}

                            />
                        </TabPane>
                        {this.state.hasRights && <TabPane tab={`Settings`} key={"settings"}>
                            <CustomDescriptions span={3} data={this.getAdjustableSettings()}
                                                width={'15%'}
                                                values={this.state.materialSettings} handleSave={this.handleSave}/>
                        </TabPane>}
                    </Tabs>
                </Col>
                </Row>
                {this.state.editModal &&
                    <EditingMaterialModal item={this.state.itemInfo} canApprove={this.state.canApprove}
                                          isModalVisible={this.state.editModal}
                                          closeModal={() => this.setState({editModal: false})} save={this.save}/>}
                <HistoryModal modalProps={{height: '60vh', maxHeight: '60vh', overflowY: 'auto'}}
                              _id={this.state.itemInfo._id} project_id={this.state.projectId} material={this.state.material}
                              isModalVisible={this.state.historyModal} onCancel={this.close} onOk={this.close}/>

                {this.state.showStockAllocation && <ModalWindow isModalVisible={this.state.showStockAllocation}
                                                                title={'Proposed Stock Allocation'}
                                                                modalProps={{width: '60vw'}}
                                                                onOk={() => this.setState({
                                                                    showStockAllocation: false,
                                                                    stockAllocation: [],
                                                                    selectedRec: null
                                                                })}
                                                                onCancel={() => this.setState({
                                                                    showStockAllocation: false,
                                                                    stockAllocation: [],
                                                                    selectedRec: null
                                                                })}>

                </ModalWindow>}

            </div>
        );
    }

}

function StockAllocationTable({recs, material, projectId}) {

    const [allocation, setAllocation] = useState([])
    const [selectedRec, setSelectedRec] = useState({})
    const [selectedIdx, setSelectedIdx] = useState(0)
    const [saInProgress, setSaInProgress] = useState(false)

    const getAllocation = (rec) => {
        setSaInProgress(true)
        api.post(`/projects/${projectId}/material-analysis/${material}/stockAllocation`, rec)
            .then(response => {
                setAllocation(response.data)

            }).finally(() => setSaInProgress(false))
    }

    useEffect(() => {
        setSelectedRec(recs[selectedIdx])
    }, [recs, selectedIdx]);

    useEffect(() => {
        getAllocation(selectedRec)
    }, [selectedRec]);


    const getColumns = () => [
        {
            title: <b>Plant</b>,
            dataIndex: 'plant',
            key: 'index',
        }, {
            title: <b>MRP Type</b>,
            dataIndex: 'MRP Type',
        }, {
            dataIndex: 'plant_stock_level',
            'title': <b>Stock Level</b>
        }, {
            dataIndex: 'plant_transactions_count',
            'title': <b>Transactions</b>
        }, {
            title: <b>Installed</b>,
            dataIndex: 'quantity',
        }, {
            title: <b>Old {ROP_MIN_LABEL}</b>,
            dataIndex: 'rop',
        }, {
            title: <b>Old Max</b>,
            dataIndex: 'max',
        }, {
            title: <b>New {ROP_MIN_LABEL}</b>,
            dataIndex: 'appr_min',
        }, {
            title: <b>New Max</b>,
            dataIndex: 'appr_max',
        }, {
            title: <b>Lead Time</b>,
            dataIndex: 'plant_lead_time',
        }, {
            title: <b>Plant Price</b>,
            dataIndex: 'plant_price',
        },
    ]

    return <div style={{width: '100%', padding: 10}}>
        {selectedRec && <Descriptions>
            <DescriptionsItem labelStyle={{fontWeight: 'bold'}} span={3}
                              label={'Selected Recommendation'}>
                <Select style={{width: 300}} value={selectedIdx} onChange={idx => setSelectedIdx(idx)}
                        options={recs.map((rec, i) => {
                            return {
                                'value': i,
                                'label': `[${rec['type']}] ${rec['name']}`
                            }
                        })}/>
            </DescriptionsItem>
            <DescriptionsItem labelStyle={{fontWeight: 'bold'}} span={3}
                              label={`Overall ${ROP_MIN_LABEL}`}>{selectedRec['min']}</DescriptionsItem>
            <DescriptionsItem labelStyle={{fontWeight: 'bold'}} span={3}
                              label={'Overall MAX'}>{selectedRec['max']}</DescriptionsItem>
        </Descriptions>}
        <Spin spinning={saInProgress}>
            <StandardTable tableSize={'small'} scroll={1000} data={allocation} tableProps={{width: '100%'}}
                           columns={getColumns()}/>
        </Spin>
    </div>

}

MinMaxItem.contextType = CurrentUserContext;
