import { Button, Statistic, Tooltip } from "antd";
import { LockButton } from "../helpers/ButtonLockRecommendation";
import { StandardTable } from "../helpers/TablePanel";
import { ROP_MIN_LABEL } from "../../properties";
import { useState, useEffect } from "react";

export function RecommendationsTable({
                                         data,
                                         onApprove,
                                         approvalStatus,
                                         onForecastOpen,
                                         couldApprove,
                                         onStockAllocationOpen,
                                         onLockRecommendation,
                                         projectId,
                                         material,
                                     }) {
    const [tableData, setTableData] = useState(data);

    useEffect(() => {
        setTableData(data);
    }, [data]);

    const handleLockRecommendation = (updatedData) => {
        setTableData(updatedData);
        onLockRecommendation(updatedData);
    };

    const getColumns = () => [
        {
            title: <b>#</b>,
            dataIndex: 'index',
            key: 'index',
            width: '5%',
            render: (text, record, index) => index + 1,
        },
        {
            title: <b>Recommendation</b>,
            dataIndex: 'name',
            key: 'name',
            width: '20%',
        },
        {
            title: <b>Type</b>,
            dataIndex: 'type',
            key: 'type',
        },
        {
            title: <b>Raw Quantity</b>,
            dataIndex: 'raw',
            key: 'raw',
            align: 'center',
        },
        {
            title: <b>Apply (%)</b>,
            dataIndex: 'apply',
            key: 'apply',
            align: 'center',
            render: (apply) => apply && apply * 100,
        },
        {
            title: <b>{ROP_MIN_LABEL}</b>,
            dataIndex: 'min',
            key: 'min',
            align: 'center',
        },
        {
            title: <b>MAX</b>,
            dataIndex: 'max',
            key: 'max',
            align: 'center',
        },
        {
            title: <b>Service factor</b>,
            dataIndex: 'service_factor',
            key: 'service_factor',
            align: 'center',
            render: (service_factor) => (
                <Statistic value={service_factor} suffix={'%'} valueStyle={{ fontSize: 14 }} />
            ),
        },
        {
            title: <b>Total Cost</b>,
            dataIndex: 'total_cost',
            key: 'total_cost',
            align: 'center',
            render: (total_cost, item) => {
                return (
                    <Tooltip
                        title={
                            <div>
                                <p>Order Cost: {item.order_cost}</p>
                                <p>Holding Cost: {item.holding_cost}</p>
                            </div>
                        }
                        placement="top"
                    >
                        {total_cost}
                    </Tooltip>
                );
            },
        },
        {
            title: <b>Number of stockouts</b>,
            dataIndex: 'stockouts',
            key: 'stockouts',
            align: 'center',
        },
        {
            title: <b>Longest Stockout (months)</b>,
            dataIndex: 'longest_stockout',
            key: 'longest_stockout',
            align: 'center',
        },
        {
            title: <b>Real Savings</b>,
            dataIndex: 'real_savings',
            key: 'real_savings',
            align: 'center',
        },
        {
            title: <b>Average Savings</b>,
            dataIndex: 'ave_savings',
            key: 'ave_savings',
            align: 'center',
        },
        {
            title: <b>Actions</b>,
            dataIndex: 'actions',
            key: 'actions',
            align: 'center',
            width: '15%',
            render: (actions, item) => {
                return (
                    <div>
                        <Button onClick={() => onForecastOpen(item)} style={{ margin: '0 5px' }}>
                            Open forecast
                        </Button>
                        {couldApprove && !approvalStatus && (
                            <Button onClick={() => onApprove(item)}> Approve</Button>
                        )}
                        <LockButton
                            projectId={projectId}
                            material={material}
                            record={item}
                            lock_recommendation={item.lock_recommendation}
                            onLockRecommendation={onLockRecommendation}
                            data={data}
                        />
                    </div>
                );
            },
        },
    ];

    return (
        <div>
            <StandardTable tableSize={'small'} scroll={1000} data={data} columns={getColumns()} />
        </div>
    );
}